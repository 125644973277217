import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Box } from '../../../components'

import Pin from './pin'

const Map = props => (
  <StaticQuery
    query={graphql`
      {
        wordpressPage(slug: { eq: "contact" }) {
          acf {
            image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 1900, quality: 90) {
                    ...GatsbyImageSharpSizes_withWebp
                  }
                }
              }
            }
            address
          }
        }
      }
    `}
    render={page => (
      <Box
        is="a"
        href="https://goo.gl/maps/49K3LTpoeQn"
        target="_blank"
        rel="noopener noreferrer"
        position="relative"
        width={[1 / 1, null, null, 1 / 2]}
        height={['50vh', null, null, '100%']}
      >
        <Img
          sizes={page.wordpressPage.acf.image.localFile.childImageSharp.sizes}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            userSelect: 'none',
            pointerEvents: 'none',
          }}
          imgStyle={{
            objectPosition: 'center center',
          }}
        />
        <Pin />
      </Box>
    )}
  />
)

export default Map
