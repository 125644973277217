import React from 'react'
import styled from 'styled-components'

import { H4, Span } from '../../../components'

const Wrapper = styled(H4)`
  display: inline-block;
  text-align: center;
  cursor: pointer;

  > span,
  > a {
    background-image: ${props =>
      `linear-gradient(180deg, transparent 92%, ${
        props.theme.colors.bg.reverse
      } 0)`};
    background-size: 0 100%;
    background-position: right center;
    background-repeat: no-repeat;
    transition: background-size 700ms cubic-bezier(0.4, 0, 0, 1);
  }

  &:hover {
    > span,
    > a {
      background-size: 100% 100%;
      background-position: left center;
      transition: background-size 900ms cubic-bezier(0.4, 0, 0, 1);
    }
  }
`

Wrapper.defaultProps = {
  mt: 3,
  textAlign: 'center',
}

const ActionHeader = props => (
  <Wrapper>
    <Span {...props}>{props.children}</Span>
  </Wrapper>
)

export default ActionHeader
