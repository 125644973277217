import React from 'react'
import { Layout, Header, MobileHeader, SEO } from '../components'
import Contact from '../views/contact'
import { FontString } from '../views/floorplans/components/fontString'

class ContactPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      index: 2,
      path: '',
      navigatePath: '',
      absorbRoute: false,
      showRight: true,
      mounted: false,
    }
  }

  componentDidMount = () => {
    let IE = false
    if (/MSIE 10/i.test(navigator.userAgent)) {
      // This is internet explorer 10
      IE = true
    }

    if (
      /MSIE 9/i.test(navigator.userAgent) ||
      /rv:11.0/i.test(navigator.userAgent)
    ) {
      // This is internet explorer 9 or 11
      IE = true
    }

    if (/Edge\/\d./i.test(navigator.userAgent)) {
      // This is Microsoft Edge
      IE = true
    }
    let w = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    )
    if (IE) {
      w = 0
    }
    if (w < 1024) {
      this.setState({ showRight: false })
    }
    this.setState({ mounted: true })
  }

  componentDidUpdate() {
    if (this.state.showRight) {
      if (
        this.props.location.pathname !== this.state.path &&
        !this.state.absorbRoute
      ) {
        this.setState({
          navigatePath: this.props.location.pathname,
          path: this.props.location.pathname,
        })
        //window.LassoCRM.tracker.track()
      }
      if (
        this.state.absorbRoute &&
        this.props.location.pathname !== this.state.path
      ) {
        this.setState({
          path: this.props.location.pathname,
          absorbRoute: false,
        })
        //window.LassoCRM.tracker.track()
      }
    }
  }

  forceRoute = route => {
    this.setState({ absorbRoute: false, path: '' }, () => {
      this.props.navigate(route)
    })
  }

  render() {
    return (
      <Layout>
        <style jsx>{`
          ${FontString};
        `}</style>
        <SEO
          title="Contact | NUDE by Battistella"
          description="Inquire about living at Battistella today."
          url="contact"
        />
        <MobileHeader />
        <Header
          index={this.state.index}
          path={this.state.path}
          isMobile={!this.state.showRight}
          forceRoute={this.forceRoute}
        />
        {this.state.mounted && <Contact />}
      </Layout>
    )
  }
}

export default ContactPage
