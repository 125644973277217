import React from 'react'
import styled, { injectGlobal, keyframes } from 'styled-components'
import { rgba } from 'polished'
import '@reach/dialog/styles.css'

import Button from '../button'
import Checkbox from '../checkbox'
import { Box, FlexRow, Text, FlexCol, FlexGrid } from '../globals'
import { H2, H6 } from '../headings'
import Select from '../select'

const CustomInput = styled.input`
  width: 100%;
  margin: 0;
  padding: 0 0 12px;
  border: 0;
  border-bottom: 2px solid ${props => props.theme.colors.text.default};
  border-radius: 0;
  font-family: ${props => props.theme.fonts.sans};
  font-weight: 500;
  letter-spacing: 0.0625em;
  text-transform: uppercase;
  color: ${props => props.theme.colors.text.default};
  background: transparent;
  &::placeholder {
    color: ${props => rgba(props.theme.colors.text.default, 1 / 3)};
    opacity: 1;
  }
  &:focus {
    outline: 0;
  }
`

injectGlobal`
  [data-reach-dialog-overlay] {
    z-index: 10000;
    padding: 16px;
  }
  [data-reach-dialog-content] {
    width: 100%;
    max-width: 800px;
    padding: 0;
  }
`

const loadingKeyframes = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`

const loadingCircleKeyframes = keyframes`
  0% {
    stroke-dashoffset: 600;
  }
  100% {
    stroke-dashoffset: 0;
  }
`

const Svg = styled.svg`
  animation: ${loadingKeyframes} 2s linear infinite;
`

const Circle = styled.circle`
  stroke-dashoffset: 600;
  stroke-dasharray: 300;
  stroke-width: 6;
  stroke-miterlimit: 10;
  stroke-linecap: square;
  animation: ${loadingCircleKeyframes} 1.6s cubic-bezier(0.4, 0.15, 0.6, 0.85)
    infinite;
  color: #333740;
  stroke: currentColor;
  fill: transparent;
`

const Spinner = () => (
  <Box width={64} height={64} style={{ lineHeight: 0 }}>
    <Svg x="0px" y="0px" viewBox="0 0 150 150">
      <Circle cx="75" cy="75" r="60" />
    </Svg>
  </Box>
)

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      botFieldEntered: false,
      botField: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      hear: '',
      broker: '',
      formState: '',
      authorized: false,
      unitType: '',
      priceRange: '',
    }
  }

  onSubmit = event => {
    event.preventDefault()
    if (
      !this.state.botFieldEntered &&
      this.state.lastName !== '' &&
      this.state.firstName !== '' &&
      this.state.email !== '' &&
      this.state.phoneNumber !== '' &&
      this.state.hear !== '' &&
      this.state.formState !== 'processing' &&
      this.state.formState !== 'success'
    ) {
      this.setState({ formState: 'processing' })

      fetch(
        `https://hooks.zapier.com/hooks/catch/7552343/bep5xud?first_name=${
          this.state.firstName
        }&last_name=${this.state.lastName}&email=${
          this.state.email
        }&phone_number=${this.state.phoneNumber}&hear=${
          this.state.hear
        }&broker=${this.state.broker}`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
          },
        }
      ).then(res => {
        if (res.status == 200) {
          this.setState({ formState: 'success' })
          window.location.href =
            process.env.NODE_ENV !== 'development'
              ? '/nude/thank-you'
              : '/thank-you'
        } else {
          this.setState({ formState: 'failure' })
        }
      })
    } else {
      this.setState({ formState: 'error' })
    }
  }

  componentDidMount = () => {
    window.LassoCRM = window.LassoCRM || {}
    ;(function(ns) {
      ns.tracker = new window.LassoAnalytics('LAS-515532-07')
    })(window.LassoCRM)
    try {
      window.LassoCRM.tracker.patchRegistrationForms()
    } catch (error) {}
  }

  render() {
    return (
      <Box
        width={1}
        maxWidth={800}
        minHeight={485}
        mx="auto"
        p={[4, 6]}
        bg="bg.default"
        is="form"
        onSubmit={this.onSubmit}
      >
        <FlexRow>
          <FlexCol position="relative" flex={1} minHeight={485}>
            {(this.state.formState === 'success' ||
              this.state.formState === 'processing' ||
              this.state.formState === 'failure' ||
              this.state.formState === 'error' ||
              this.state.formState === '') && (
              <Box
                style={{
                  opacity: this.state.formState === 'processing' ? 0.3 : 1,
                  pointerEvents:
                    this.state.formState === 'processing' ? 'none' : 'all',
                }}
              >
                <H2 children={`Get ready for a big future.`} mb={3} />
                <H6 children={`Inquire about living at Battistella today.`} />
                <Box mt={4}>
                  <FlexGrid gutterX={[4]} gutterY={[4]}>
                    <Box width={[1, 1 / 3]}>
                      <CustomInput
                        placeholder={`First name`}
                        type="text"
                        value={this.state.firstName}
                        onChange={e => {
                          this.setState({ firstName: e.target.value })
                        }}
                      />
                    </Box>
                    <Box width={[1, 1 / 3]}>
                      <CustomInput
                        placeholder={`Last name`}
                        type="text"
                        value={this.state.lastName}
                        onChange={e => {
                          this.setState({ lastName: e.target.value })
                        }}
                      />
                    </Box>
                    <Box width={[1, 1 / 3]}>
                      <CustomInput
                        placeholder={`Phone`}
                        type="text"
                        value={this.state.phoneNumber}
                        onChange={e => {
                          this.setState({ phoneNumber: e.target.value })
                        }}
                      />
                    </Box>
                    <Box width={1}>
                      <CustomInput
                        placeholder={`Email`}
                        type="email"
                        value={this.state.email}
                        onChange={e => {
                          this.setState({ email: e.target.value })
                        }}
                      />
                    </Box>

                    <Box width={1}>
                      <Select
                        value={this.state.hear}
                        onChange={e => {
                          this.setState({ hear: e.target.value })
                        }}
                        required
                        width="100%"
                      >
                        <option
                          children="How did you hear about us"
                          value=""
                          disabled
                        />
                        <option
                          children="Battistella website"
                          value="website"
                        />
                        <option children="Online ad" value="online_ad" />
                        <option children="Signage" value="signage" />
                        <option children="Social Media" value="social_media" />
                        <option
                          children="Through my agent"
                          value="through_my_agent"
                        />
                        <option
                          children="Word of mouth"
                          value="word_of_mouth"
                        />
                        <option children="Other" value="other" />
                      </Select>
                    </Box>
                    <Box width={1}>
                      <Select
                        value={this.state.broker}
                        onChange={e => {
                          this.setState({ broker: e.target.value })
                        }}
                        required
                        width="100%"
                      >
                        <option
                          children="Are you a Broker/Agent?"
                          value=""
                          disabled
                        />
                        <option children="Yes" value="yes" />
                        <option children="No" value="no" />
                      </Select>
                    </Box>
                  </FlexGrid>
                </Box>
                <Box mt={4}>
                  <Button
                    id="sendButton"
                    type="submit"
                    children={`Send`}
                    width={112}
                    appearance="nude"
                  />
                </Box>
                <Box mt={1}>
                  {this.state.formState === 'error' && (
                    <Text
                      children={
                        'All fields are required, and you must authorize communications.'
                      }
                      color="red"
                    />
                  )}
                  {this.state.formState === 'failure' && (
                    <Text
                      children={
                        'There was an error processing your submission. Please try again later'
                      }
                      color="red"
                    />
                  )}
                </Box>
                <Box mt={4}>
                  <Checkbox
                    checked={this.state.authorized}
                    onClick={event => {
                      this.setState({ authorized: !this.state.authorized })
                      event.stopPropagation()
                      event.preventDefault()
                    }}
                  >
                    <Text
                      children={`By checking this box, I authorize Battistella Developments to provide me with information electronically. Battistella Developments will not sell or make your private information to any third party.`}
                      ml="12px"
                      fontSize="10px"
                      fontWeight={500}
                      lineHeight={1.2}
                      letterSpacing="0.04em"
                      style={{ textTransform: 'uppercase' }}
                    />
                  </Checkbox>
                </Box>
              </Box>
            )}
            {this.state.formState === 'processing' && (
              <FlexRow
                position="absolute"
                top={0}
                left={0}
                width={1}
                justifyContent="center"
                alignItems="center"
                height="100%"
                bg="#fff"
              >
                <Spinner />
              </FlexRow>
            )}
          </FlexCol>
        </FlexRow>
      </Box>
    )
  }
}

export default Form
