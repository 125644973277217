import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import { Box, FlexCol, FlexGrid, H3, SocialMediaLinks } from '../../components'

import ActionHeader from './components/actionHeader'
import Map from './components/map'
import Form from '../../components/popUp/form'

const Contact = props => (
  <StaticQuery
    query={graphql`
      {
        wordpressPage(slug: { eq: "contact" }) {
          acf {
            phone_label
            phone
            email_label
            email
            address_label
            address
            hours
            social_label
          }
        }
      }
    `}
    render={page => (
      <Box height={['auto', 'auto', 'auto', '100vh']} bg="bg.default">
        <Box height="100%">
          <FlexGrid width={1 / 1} height="100%">
            <Map />
            <FlexCol
              justifyContent="center"
              alignItems="center"
              width={[1 / 1, null, null, 1 / 2]}
              height="100%"
              order={[1, null, null, 0]}
            >
              <Form />
              <FlexCol
                justifyContent="center"
                alignItems="center"
                width={1 / 1}
                // height="100%"
                mt={[0, null, null, 0]}
                mb={[64, null, null, 0]}
                mx={[32, null, null, 0]}
                style={{backgroundColor: 'white'}}
              >
                <FlexGrid
                  width={1 / 1}
                  gutterX={10}
                  gutterY={[5, null, null, 6]}
                  flexWrap={['wrap', 'wrap', 'wrap', 'nowrap']}
                >
                  <FlexCol alignItems="center" width={1 / 1}>
                    <H3
                      children={page.wordpressPage.acf.phone_label}
                      textAlign="center"
                    />
                    <ActionHeader
                      children="587-871-0895"
                      is="a"
                      href={'sms:587-871-0895'}
                      style={{ whiteSpace: 'nowrap' }}
                    />
                  </FlexCol>
                  <FlexCol alignItems="center" width={1 / 1}>
                    <H3
                      children={page.wordpressPage.acf.email_label}
                      textAlign="center"
                    />
                    <ActionHeader
                      children="info@battistella.ca"
                      is="a"
                      href={'mailto:info@battistella.ca'}
                    />
                  </FlexCol>
                  {/* <FlexCol alignItems="center" width={1 / 1}>
                    <H3
                      children={page.wordpressPage.acf.address_label}
                      textAlign="center"
                    />
                    <FlexCol is="address" alignItems="center">
                      <ActionHeader>
                        <Span
                          is="a"
                          href="https://goo.gl/maps/49K3LTpoeQn"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ fontStyle: 'normal' }}
                          dangerouslySetInnerHTML={{
                            __html: page.wordpressPage.acf.address,
                          }}
                        />
                      </ActionHeader>
                      <Box mt={2}>
                        <H4
                          style={{ fontStyle: 'normal' }}
                          dangerouslySetInnerHTML={{
                            __html: page.wordpressPage.acf.hours,
                          }}
                          textAlign="center"
                        />
                      </Box>
                    </FlexCol>
                  </FlexCol> */}
                  <FlexCol alignItems="center" width={1 / 1}>
                    <H3
                      children={page.wordpressPage.acf.social_label}
                      textAlign="center"
                    />
                    <SocialMediaLinks mt={3} />
                  </FlexCol>
                </FlexGrid>
              </FlexCol>
            </FlexCol>
          </FlexGrid>
        </Box>
      </Box>
    )}
  />
)

export default Contact
