import React from 'react'

import { Box, NUDELogo } from '../../../components'

import { PinWrapper, PinContainer } from './style'

const Pin = props => (
  <PinWrapper>
    <PinContainer px={[24, null, 32]} py={[16, null, 24]}>
      <Box width={[96, null, 128]}>
        <NUDELogo color="#fff" />
      </Box>
    </PinContainer>
  </PinWrapper>
)

export default Pin
