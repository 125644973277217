import styled from 'styled-components'

import { Box } from '../../../components'

export const PinWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
`

export const PinContainer = styled(Box)`
  position: relative;
  border-radius: 4px;
  background-color: #333740;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.25);

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 1;
    width: 0;
    height: 0;
    border-top: solid 24px #333740;
    border-left: solid 24px transparent;
    border-right: solid 24px transparent;
    /* box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.25); */
    transform: translate(-50%, -8px);
  }
`
